<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData"/>
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'brandschaden-wiesbaden',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath: 'https://medo-sanierung.de/public_files/img/references/brandschaden-wiesbaden',
      imageCount: 29,
      imageFileFormat: '.jpg',
      name: 'Sanierungsarbeiten nach Brandschaden',
      description:
          'Nachdem das Erdgeschoss eines Einfamilienhauses in Wiesbaden von einem Brand schwerst betroffen wurde, standen hier einige Sanierungsmaßnahmen an. Von Boden- über Wand- bis hin zu Deckenarbeiten war hier alles dabei, was das Handwerkerherz begehrt.',
    },
    //
  }),
};
</script>
